.badgeok {
  color: green;
  border-color: green;
}
.badgenotok {
  color: red;
  border-color: red;
}
.badgenontont {
  color:#ff4500;
  border-color: #ff4500;
}
.modal-backdrop {
  background-color: green;
}
.my-modal .modal-content {
  background-color: green;
}
