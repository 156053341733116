.formrequired {
  content: '*';
  color: red;
}

input[type='checkbox'] {
  height: 50px;
  width: 20px;
  border: 1px solid #f00;
}
.form-check-label {
  margin-top: 16px;
  margin-left: 10px;
  color: crimson;
  font-size: large;
  font-weight: bold;
font-style: italic;
}
