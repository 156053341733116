th {
    background-color: #e2101a;
    font: normal bold 20px/27px Segoe UI;
    font-weight: bold;
    color: #ffffff;
    font-weight: 600;
    white-space: nowrap;
    font-family: serif;
    /* font-size: 150%; */
  }
  td {
    max-height: 10px;
  }
  tr {
    max-height: 10px;
  }
  .btnof {
    font-size: large;
    border-radius: 4px;
    display: inline-block;
    padding: 5px;
    width: 200px;
    color: rgb(248, 78, 78);
    background-color: rgba(248, 78, 78, 0.133);
  }
  .btnon {
    font-size: large;
    border-radius: 4px;
    display: inline-block;
    padding: 5px;
    width: 200px;
    color: rgb(61, 184, 124);
    background-color: rgba(61, 184, 124, 0.133);
  }
  .btn-add {
    color: #fff;
    background-color: #e2101a;
    align-items: center;
    background: #e2101a;
    border: 2px solid transparent;
    border-radius: 8px;
    display: flex;
    font-weight: 600;
    height: 3em;
    min-width: 2.5em;
    padding: 0 1em;
    position: relative;
    transition: 0.2s ease;
    width: 100%;
  }
  .mt-title {
    margin-top: 50px;
  }
  
  TextField:focus {
    box-shadow: 0 0 0px 2px #e2101a;
    border-radius: 100px;
  }
  TextField {
    border-radius: 100px;
    box-shadow: 0 0 0px 2px rgb(255, 0, 0);
  }
  .st {
    margin: 0px;
  }
  