.texttheme {
  color: #004184 !important;
}
.icontheme1 {
  width: 70px;
  height: 70px;
  border-radius: 40%;
  padding: 15px;
  background-color: rgba(190,245,116, 0.5);
}
.icontheme2 {
    width: 70px;
    height: 70px;
    border-radius: 40%;
    padding: 10px;
    background-color: rgba(185,236,240, 0.5);
  }

  .icontheme3 {
    width: 70px;
    height: 70px;
    border-radius: 40%;
    padding: 10px;/* rgb(219,112,147) */
    background-color: rgba(251,191,255, 0.5);
  }

  .icontheme4 {
    width: 70px;
    height: 70px;
    border-radius: 40%;
    padding: 10px;
    background-color: rgba(255,255,0, 0.5);
  }
  

/* Dashed border */
hr.dashed {
  border-top: 3px dashed #fff;
}

/* Dotted border */
hr.dotted {
  border-top: 3px dotted #fff;
}

/* Solid border */
hr.solid {
  border-top: 3px solid #fff;
}

/* Rounded border */
hr.rounded {
  border-top: 8px solid #fff;
  border-radius: 5px;
}

.sizecard{
  width: 50%;
  color: #004184 !important;
}