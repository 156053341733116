.badgeok {
  color: green;
  border-color: green;
}
.badgenotok {
  color: red;
  border-color: red;
  box-shadow: -2.5px 5px 9px red;
}
.badgenontont {
  color:#ff1e00;
  border-color: #ff1e00;
  box-shadow: 2.5px 5px 9px  #ff1e00;
}
.modal-backdrop {
  background-color: green;
}
.my-modal .modal-content {
  background-color: green;
}
